/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://w7n5dszdwnbxfcvhb7b5qwfzey.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gb6zwk2ctvas3bwtuuyis5smny"
};


export default awsmobile;
